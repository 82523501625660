/* eslint-disable react/no-danger */
/* eslint-disable react/no-children-prop */
import type { FC } from 'react';
import React, { memo, useCallback, useState, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl-next';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { Global } from '@emotion/core';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import {
	Banner,
	RightSidebar as AtlaskitRightSidebar,
	PageLayout as Grid,
	Main,
	Content,
	TopNavigation,
} from '@atlaskit/page-layout';
import { SpotlightManager } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import UFOSegment from '@atlaskit/react-ufo/segment';

import { EditContentButtonPreloaderContext } from '@confluence/edit-button';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { PerformanceStart, PERFORMANCE_SUBJECT_navigation } from '@confluence/performance';
import { ProgressBar } from '@confluence/global-progress-bar';
import { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';
import { AppNavigationBase } from '@confluence/app-navigation/entry-points/appNavigation';
import { useSpaceGettingStartedTour } from '@confluence/experiment-space-tour';
import { BannerContainer } from '@confluence/banner-container';
import { BannerStateContainer } from '@confluence/banners';
import {
	SHOW_SWITCHER_SPOTLIGHT_ONLY,
	JSW_XFLOW_SWITCHER_TOUR_TASK,
} from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';
import { useQuickstartEligible, useQuickstartState } from '@confluence/onboarding-quickstart';
import { FixedBreadcrumbsNavForPageLayoutLoader } from '@confluence/breadcrumbs';
import {
	SPACE_ANALYTICS,
	CUSTOM_CONTENTS_ON_PAGE_LIST,
	SPACE_SETTINGS_LANDING_PAGE,
	SPACE_PAGES,
	SPACE_BLOGS,
	SPACE_CALENDARS,
	DATABASE_CUSTOM_OVERVIEW,
	SPACE_QUESTIONS,
} from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import type { Route } from '@confluence/route';
import { perfMark, WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { GlobalComponentsLoader } from '@confluence/global-components';
import { PreviewPanel } from '@confluence/preview-panel-api';
import { fg } from '@confluence/feature-gating';

import { NavigationExperienceWrapper } from '../NavigationExperienceWrapper';
import { useScrollTree } from '../useScrollTree';
import { i18n } from '../messages';
import type { PageLayoutProps } from '../PageLayoutTypes';
import {
	ContentRightSidePanel,
	LayoutMainWrapper,
	ZIndexWrapper,
	globalStylesCompiled,
	fixSSRContentForNewLayoutWithRightSidebarStyles,
	SSR_LAYOUT_DATA_ATTR,
	SSR_IFRAME_ATTR,
	styleCodeBlockFix,
	fontFamilyCLSFix,
	styleNoPrintNav,
	moreActionDropdownCLSFix,
	StyleFixSSRContentForNewLayoutWithRightSidebar,
} from '../presentationComponents';
import { LazyImpersonationFlag } from '../impersonation/LazyImpersonationFlag';
import { PageLayoutContextProviderWrapper } from '../PageLayoutContextProviderWrapper';

import { RightSidebarLoader } from './RightSidebarLoader';
import { SideNavigationSwitcher } from './SideNavigationSwitcher';

const resize = () => {
	window.dispatchEvent(new Event('resize'));
};

const isRoute = (pathname: string | undefined, route: Route) =>
	Boolean(route.match(pathname || ''));

const routesToShowPageLayoutBreadcrumbNav = [
	SPACE_ANALYTICS,
	CUSTOM_CONTENTS_ON_PAGE_LIST,
	SPACE_SETTINGS_LANDING_PAGE,
	SPACE_PAGES,
	SPACE_BLOGS,
	SPACE_CALENDARS,
	DATABASE_CUSTOM_OVERVIEW,
	SPACE_QUESTIONS,
];

const TOP_NAV_HEIGHT = 56;

export const PageLayout: FC<PageLayoutProps> = memo(
	({ children, view, standalone, isSpaceSettingsScreen, editorPreloader }) => {
		return (
			<ErrorBoundary
				attribution={Attribution.BACKBONE}
				attributes={{
					errorBoundaryId: 'PageLayout-outer',
				}}
			>
				<PageLayoutInner
					children={children}
					view={view}
					standalone={standalone}
					isSpaceSettingsScreen={isSpaceSettingsScreen}
					editorPreloader={editorPreloader}
				/>
			</ErrorBoundary>
		);
	},
);

const PageLayoutInner = ({
	children,
	view,
	standalone,
	isSpaceSettingsScreen,
	editorPreloader,
}) => {
	const [isInProductHelpOn, setIsInProductHelpOn] = useState(false);
	const toggleInProductHelp = (val) => {
		setIsInProductHelpOn(val);
	};

	const { scrollTree, pageTreeUpdater } = useScrollTree({ view });

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isOnEditRoute = useIsEditorPage();
	const fireSidebarToggleAnalytics = useCallback(
		(action: 'collapsed' | 'expanded') => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action,
					actionSubject: 'spaceNavigation',
					source: 'containerNavigation',
					attributes: {
						isOnEditRoute,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, isOnEditRoute],
	);

	const onExpand = useCallback(() => {
		confluenceLocalStorageInstance.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
			'expanded',
		);

		scrollTree();
		resize();
		fireSidebarToggleAnalytics('expanded');
	}, [scrollTree, fireSidebarToggleAnalytics]);

	const onCollapse = useCallback(() => {
		confluenceLocalStorageInstance.setItem(
			PERSISTED_KEYS_ON_SERVER.PERSISTED_LEFT_SIDEBAR_COLLAPSED,
			'collapsed',
		);

		resize();
		fireSidebarToggleAnalytics('collapsed');
	}, [fireSidebarToggleAnalytics]);

	const { isLoading, isError, isPanelOpen, isDismissed, openComponentId } = useQuickstartState();
	const { isQuickstartEligible } = useQuickstartEligible();

	const jswSpotlightOpen =
		openComponentId === SHOW_SWITCHER_SPOTLIGHT_ONLY ||
		openComponentId === JSW_XFLOW_SWITCHER_TOUR_TASK;

	// By default, the blanket should not be tinted, only tinted if onboarding jsw switcher spotlights are active
	const spotlightTriggeredByJswQS = isPanelOpen && !isDismissed && jswSpotlightOpen;

	const isQuickStartOpen =
		isPanelOpen && !isDismissed && !isLoading && !isError && isQuickstartEligible();

	const intl = useIntl();
	const { location } = useContext(RoutesContext);

	// show breadcrumb nav for routes listed in routesToShowPageLayoutBreadcrumbNav
	const showBreadcrumbNavHeader = routesToShowPageLayoutBreadcrumbNav.some((route) =>
		isRoute(location?.pathname, route),
	);

	const SideNavSkiplinkText = intl.formatMessage(i18n.SideNav);

	const EditContentButtonPreloaderContextValue = useMemo(
		() => editorPreloader || (() => {}),
		[editorPreloader],
	);

	const [{ isSpaceGettingStartedTourInProgress }] = useSpaceGettingStartedTour();

	const nonceValue = window.__SSR_NONCE_VALUE__;

	const standaloneGlobalStyles = (bannerHeight) =>
		standalone ? (
			<Global
				data-testid="global-styles-SSR"
				styles={[
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					StyleFixSSRContentForNewLayoutWithRightSidebar(
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						bannerHeight + TOP_NAV_HEIGHT,
					),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					styleCodeBlockFix,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					fontFamilyCLSFix,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					styleNoPrintNav,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					moreActionDropdownCLSFix,
				]}
			/>
		) : (
			<Global
				data-testid="global-styles"
				styles={[
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					styleCodeBlockFix,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					fontFamilyCLSFix,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					styleNoPrintNav,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					moreActionDropdownCLSFix,
				]}
			/>
		);

	const standaloneGlobalStylesCompiled = (bannerHeight) =>
		standalone ? (
			/*
			 * We're injecting global styles to handle styling targets that are not part of the React app, but are children of the HTML body
			 * Previously, this used to be a `<Global styles={[…]} />` from Emotion
			 * But in the Compiled migration, there is no alternative and feels like the best path forward for now.
			 */
			<>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles */}
				<style
					dangerouslySetInnerHTML={{ __html: fixSSRContentForNewLayoutWithRightSidebarStyles }}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles */}
				<style
					{...(nonceValue !== undefined ? { nonce: nonceValue } : {})}
					type="text/css"
					dangerouslySetInnerHTML={{ __html: globalStylesCompiled }}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles */}
				<style>{`[${SSR_LAYOUT_DATA_ATTR}], [${SSR_IFRAME_ATTR}] {
					top: ${bannerHeight + TOP_NAV_HEIGHT + 2}px !important;
				}`}</style>
			</>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles
			<style
				{...(nonceValue !== undefined ? { nonce: nonceValue } : {})}
				type="text/css"
				dangerouslySetInnerHTML={{ __html: globalStylesCompiled }}
			/>
		);

	return (
		<ErrorBoundary
			attribution={Attribution.BACKBONE}
			attributes={{
				errorBoundaryId: 'PageLayoutInner',
			}}
		>
			<NavigationExperienceWrapper view={view} navVersion="3">
				<PerformanceStart subject={PERFORMANCE_SUBJECT_navigation} subjectId="NavigationLoading" />
				<EditContentButtonPreloaderContext.Provider value={EditContentButtonPreloaderContextValue}>
					<Subscribe to={[BannerStateContainer]}>
						{(bannerState: BannerStateContainer) => {
							const bannerHeight = bannerState.getTotalHeight(false);

							return (
								<SpotlightManager
									blanketIsTinted={isSpaceGettingStartedTourInProgress || spotlightTriggeredByJswQS}
								>
									{fg('confluence_frontend_page_layout_compiled')
										? standaloneGlobalStylesCompiled(bannerHeight)
										: standaloneGlobalStyles(bannerHeight)}
									<PageLayoutContextProviderWrapper>
										<Grid
											onLeftSidebarExpand={onExpand}
											onLeftSidebarCollapse={onCollapse}
											skipLinksLabel={intl.formatMessage(i18n.SkipLinks)}
										>
											<ZIndexWrapper zIndex={14} showQuickStart>
												<Banner height={bannerHeight} isFixed>
													<BannerContainer />
												</Banner>
												{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_TOP_NAV}.start`)}
												<UFOSegment name="top-nav">
													<TopNavigation
														testId="grid-topNav"
														skipLinkTitle={intl.formatMessage(i18n.TopNav)}
														id="AkTopNav"
														height={TOP_NAV_HEIGHT}
														isFixed
													>
														<AppNavigationBase />
														<LazyImpersonationFlag />
														<ProgressBar isNav4={false} />
													</TopNavigation>
												</UFOSegment>
												{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_TOP_NAV}.end`)}
											</ZIndexWrapper>
											<Content>
												{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_SIDE_NAV}.start`)}
												<UFOSegment name="side-nav">
													<SideNavigationSwitcher
														view={view}
														skiplinkText={SideNavSkiplinkText}
														isSpaceSettingsScreen={isSpaceSettingsScreen}
													/>
												</UFOSegment>
												{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_SIDE_NAV}.end`)}
												<LayoutMainWrapper
													id="layout-main-wrapper"
													showBreadcrumbNavHeader={showBreadcrumbNavHeader}
												>
													{showBreadcrumbNavHeader && <FixedBreadcrumbsNavForPageLayoutLoader />}
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_MAIN}.start`)}
													<Main
														testId="grid-main-container"
														id="AkMainContent"
														skipLinkTitle={intl.formatMessage(i18n.Main)}
													>
														{children && !standalone ? (
															<ErrorBoundary attribution={Attribution.DISCO}>
																{children}
															</ErrorBoundary>
														) : null}
													</Main>
													{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_MAIN}.end`)}
												</LayoutMainWrapper>
												<RightSidebarLoader toggleInProductHelp={toggleInProductHelp} />
												<ZIndexWrapper
													zIndex={10}
													showQuickStart={isQuickStartOpen && !isInProductHelpOn}
												>
													<AtlaskitRightSidebar
														width={isQuickStartOpen ? 368 : 0}
														isFixed={isQuickStartOpen}
													>
														<ContentRightSidePanel id="content-right-side-panel" />
													</AtlaskitRightSidebar>
												</ZIndexWrapper>
												<Subscribe to={[PageTreeStateUpdater]}>
													{(pageTreeStateUpdatesContainer: PageTreeStateUpdater) => {
														pageTreeUpdater.current = pageTreeStateUpdatesContainer;
														return null;
													}}
												</Subscribe>
												<PreviewPanel />
											</Content>
										</Grid>
										<GlobalComponentsLoader />
									</PageLayoutContextProviderWrapper>
								</SpotlightManager>
							);
						}}
					</Subscribe>
				</EditContentButtonPreloaderContext.Provider>
			</NavigationExperienceWrapper>
		</ErrorBoundary>
	);
};
